<template>
  <transition name="shrink-fade">
    <div class="panel error" v-if="formErrorMessage">
      <p>
        {{ formErrorMessage }}
      </p>
      <div v-if="personSearchError" class="menu">
        <a class="button menu" @click.prevent="newSearch">OK</a>
      </div>
    </div>
  </transition>

  <transition name="shrink-fade">
    <form v-if="showPersonSearchForm">
      <label>L-Number</label>
      <input type="text" required pattern="^L[0-9]{8}" v-model="lnumber" />
      <a class="button menu" @click.prevent="getPersonInfo">Search</a>
    </form>
  </transition>

  <transition name="shrink-fade">
    <form v-if="showPersonActionsForm">
      <a class="button action" @click.prevent="initHireForm">Hire</a>
      <a class="button action" @click.prevent="initTermForm">Terminate</a>
      <a class="button action" @click.prevent="initRetireForm">Retire</a>
      <a class="button action" @click.prevent="initTransferForm">Transfer</a>
      <a class="button menu" @click.prevent="newSearch">New Search</a>
      <a class="button menu" @click.prevent="initActivityLog">Activity Log</a>
    </form>
  </transition>

  <transition name="shrink-fade">
    <div class="panel" v-if="showActivityLog">
      <table class="data_table" id="activity">
        <thead>
          <th>Issued</th>
          <th>Action</th>
          <th>Job Title</th>
          <th>Status</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="item in activityLog" :key="item.reqid">
            <td>{{ tstampToDate(item.issued).toDateString() }}</td>
            <td>
              <span
                class="hover-details"
                :title="formatActivityDetails(item)"
                tabindex="0"
                >{{ item.action.action }}</span
              >
            </td>
            <td>{{ item.action.job_title }}</td>
            <td>{{ item.status.replace("_", " ") }}</td>
            <td>
              <span
                v-if="item.status == 'pending' && item.action.termination_date"
                title="Cancel this pending termination."
                @click="cancelPendingTermination($event, item)"
                ><font-awesome-icon icon="trash"
              /></span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="menu">
        <a class="button menu" @click.prevent="backToActions">Back</a>
      </div>
    </div>
  </transition>

  <transition name="shrink-fade">
    <form
      :class="{ errors: isValidationError }"
      ref="hireForm"
      v-if="showHireForm"
    >
      <div class="menu">
        <a class="button menu" @click.prevent="backToActions">Back</a>
      </div>
      <label>Employment Date</label>
      <div class="datepicker-wrapper">
        <input type="date" v-model="employmentDate" required />
      </div>
      <label>NetID Deactivation Date</label>
      <div class="datepicker-wrapper">
        <input type="date" v-model="employmentEndDate" />
      </div>
      <label>Job Title</label>
      <input type="text" class="rounded" v-model="jobTitle" />
      <div>
        <input type="checkbox" value="faculty" v-model="isFaculty" />
        <label class="checkbox">Faculty</label>
      </div>
      <label>Supervisor Email</label>
      <input
        type="email"
        class="rounded"
        pattern=".+@lafayette\.edu"
        title="Please enter an @lafayette.edu email address."
        v-model="supervisorEmail"
        required
      />
      <label>Replaces</label>
      <input type="text" class="rounded" v-model="replaces" />
      <a class="button menu" @click="processHireForm">Submit</a>
    </form>
  </transition>

  <transition name="shrink-fade">
    <form
      :class="{ errors: isValidationError }"
      ref="termForm"
      v-if="showTermForm"
    >
      <div class="menu">
        <a class="button menu" @click.prevent="backToActions">Back</a>
      </div>
      <label>NetID Deactivation Date</label>
      <div class="datepicker-wrapper">
        <input type="date" v-model="employmentEndDate" required />
      </div>
      <label>Job Title</label>
      <input type="text" class="rounded" v-model="jobTitle" />
      <label>Supervisor Email</label>
      <input
        type="email"
        class="rounded"
        pattern=".+@lafayette\.edu"
        title="Please enter an @lafayette.edu email address."
        v-model="supervisorEmail"
        required
      />
      <a class="button menu" @click="processTermForm">Submit</a>
    </form>
  </transition>

  <transition name="shrink-fade">
    <form
      :class="{ errors: isValidationError }"
      ref="retireForm"
      v-if="showRetireForm"
    >
      <div class="menu">
        <a class="button menu" @click.prevent="backToActions">Back</a>
      </div>
      <label>Retirement Date</label>
      <div class="datepicker-wrapper">
        <input type="date" v-model="employmentEndDate" required />
      </div>
      <label>Job Title</label>
      <input type="text" class="rounded" v-model="jobTitle" />
      <label>Supervisor Email</label>
      <input
        type="email"
        class="rounded"
        pattern=".+@lafayette\.edu"
        title="Please enter an @lafayette.edu email address."
        v-model="supervisorEmail"
        required
      />
      <a class="button menu" @click="processRetireForm">Submit</a>
    </form>
  </transition>

  <transition name="shrink-fade">
    <form
      :class="{ errors: isValidationError }"
      ref="transferForm"
      v-if="showTransferForm"
    >
      <div class="menu">
        <a class="button menu" @click.prevent="backToActions">Back</a>
      </div>
      <label>Transfer Date</label>
      <div class="datepicker-wrapper">
        <input type="date" v-model="employmentDate" required />
      </div>
      <label>New Job Title</label>
      <input type="text" class="rounded" v-model="jobTitle" />
      <div>
        <input type="checkbox" value="faculty" v-model="isFaculty" />
        <label class="checkbox" title="Is the new role a faculty position?"
          >Faculty</label
        >
      </div>
      <label>Supervisor Email</label>
      <input
        type="email"
        class="rounded"
        pattern=".+@lafayette\.edu"
        title="Please enter an @lafayette.edu email address."
        v-model="supervisorEmail"
        required
      />
      <label>Replaces</label>
      <input type="text" class="rounded" v-model="replaces" />
      <a class="button menu" @click="processTransferForm">Submit</a>
    </form>
  </transition>

  <transition name="shrink-fade">
    <ErrorPane v-if="showError" />
  </transition>

  <transition name="shrink-fade">
    <SuccessPane v-if="showSuccess" @successComplete="newSearch" />
  </transition>

  <transition name="shrink-fade">
    <div class="panel" v-if="showSearchingForPerson">
      <h4>Searching ...</h4>
    </div>
  </transition>

  <transition name="shrink-fade">
    <div class="panel" v-if="showProcessingForm">
      <h4>Processing request ...</h4>
    </div>
  </transition>

  <transition name="shrink-fade">
    <div class="panel" v-if="person">
      <h4>Person Information</h4>
      <table class="data_table" id="person">
        <tbody>
          <tr v-for="(value, name) in person" :key="name">
            <template v-if="!name.endsWith('_')">
              <td class="label">
                {{ name.replaceAll("_", " ").toLowerCase() }}
              </td>
              <td>{{ value }}</td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </transition>

  <transition name="shrink-fade">
    <div class="panel" v-if="netidStatus">
      <h4>NetID Status</h4>
      <table class="data_table" id="netid">
        <tbody>
          <tr>
            <td class="label">NetID</td>
            <td v-if="netidStatus.status >= 1">{{ netidStatus.netid }}</td>
            <td v-if="netidStatus.status <= 0">n/a</td>
          </tr>
          <tr>
            <td class="label">Status</td>
            <td v-if="netidStatus.status == -1">
              Malformed directory entry. Contact ITS Help Desk.
            </td>
            <td v-if="netidStatus.status == 0">NetID does not exist.</td>
            <td v-if="netidStatus.status == 1">
              NetID not activated as employee.
            </td>
            <td v-if="netidStatus.status == 2">NetID activated as employee.</td>
            <td v-if="netidStatus.status == 3">NetID activated as faculty.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </transition>
</template>

<script>
// @ is an alias to /src
import { onMounted } from "vue";
import { isAuthorized, permUser, permAdmin } from "@/utils/permissions";
import { refreshTokenIfRequired, testToken } from "@/utils/token";
import ErrorPane from "@/components/ErrorPane";
import SuccessPane from "@/components/SuccessPane";
import { useRouter } from "vue-router";
import { employeeForms } from "@/compositions/employeeForms";
import { tstampToDate } from "@/utils/datetime";

export default {
  name: "EmployeeNetid",
  components: { ErrorPane, SuccessPane },
  setup() {
    const router = useRouter();

    // Refresh the access token if required when mounting this component.
    function initComponent() {
      refreshTokenIfRequired();
      testToken();
      if (!isAuthorized(permAdmin | permUser)) {
        router.push("/login");
      }
    }
    onMounted(initComponent);

    const symbolMap = employeeForms(router);
    return { ...symbolMap, tstampToDate };
  },
};
</script>

<style></style>
