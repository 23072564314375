<template>
  <div id="nav">
    <Navbar />
  </div>
  <router-view />
</template>

<script>
import Navbar from "@/views/Navbar";

export default {
  name: "App",
  components: { Navbar },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 10px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

body {
  background: #f4f4f4;
}

/*
Styles used throughout app.
*/

.top-spacer {
  margin-top: 10px;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

td.label {
  text-transform: capitalize;
}

div.panel {
  margin: 15px auto;
  max-width: 420px;
  background: white;
  border-radius: 10px;
  padding: 20px 40px 20px 40px;
}

div.wide-panel {
  margin: 15px auto;
  max-width: 800px;
  background: white;
  border-radius: 10px;
  padding: 20px 40px 20px 40px;
}

div.panel.error {
  background-color: #ffbaba;
  font-weight: bold;
  color: black;
  border: 2px solid black;
}

table.data_table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

table.data_table td {
  border: 1px solid #ddd;
  padding: 8px;
}

table.data_table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table.data_table tr:hover {
  background-color: #ddd;
}

table.data_table th {
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #4e9af1;
  color: white;
}

button.search {
  margin: 10px auto;
  width: 100%;
}

button.action {
  margin: 10px;
}

a.action {
  background-color: #4e9af1;
}

a.menu {
  background-color: #9a4ef1;
}

a.button {
  display: inline-block;
  padding: 0.3em 1.2em;
  margin: 0 0.1em 0.1em 0;
  border: 0.16em solid rgba(255, 255, 255, 0);
  border-radius: 2em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #ffffff;
  text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
  text-align: center;
  transition: all 0.2s;
}
a.button:hover {
  border-color: rgba(0, 0, 0, 1);
}
@media all and (max-width: 30em) {
  a.button {
    display: block;
    margin: 0.2em auto;
  }
}

div.menu {
  margin: 10px;
}

form {
  background: white;
  text-align: left;
  max-width: 420px;
  margin: 10px auto;
  border-radius: 10px;
  padding: 20px 40px 20px 40px;
}

form.errors :invalid {
  outline: 2px solid red;
}

label {
  color: #aaa;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
}

label.checkbox {
  display: inline;
  text-transform: none;
  color: #000;
  font-weight: none;
}

/* Form inputs
*/
input {
  display: block;
  width: 100%;
  border-bottom: 1px solid #ddd;
  color: #555;
  box-sizing: border-box;
  margin: 10px auto;
}

div.datepicker-wrapper input {
  display: inline;
  width: auto;
}

input[type="checkbox"] {
  display: inline;
  width: auto;
  margin: 3px 3px 3px 5px;
}

span.hover-details {
  text-decoration: underline dotted green;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.shrink-fade-enter-active {
  transition: all 0.5s ease-out;
}

.shrink-fade-leave-active {
  transition: all 0.5s ease-in;
}

.shrink-fade-enter-from,
.shrink-fade-leave-to {
  transform: scaley(0.1);
  opacity: 0;
}

.rounded {
  border-radius: 10px;
}

/* Tooltips for mobile */
/*Functional Styling*/
@media (pointer: coarse), (hover: none) {
  [title] {
    position: relative;
    display: flex;
    justify-content: center;
  }
  [title]:focus::after {
    content: attr(title);
    position: absolute;
    top: 90%;
    color: #000;
    background-color: #fff;
    border: 1px solid;
    width: fit-content;
    padding: 3px;
  }
}
</style>
