import { ref } from "vue";
import { loadAuthInfo, refreshTokenIfRequired } from "@/utils/token";
import { tstampToDate, dateToMMDDYYYY } from "@/utils/datetime";
import { lookupPersonRecord } from "@/utils/person";

function requestHistorySetup(router) {
  const activities = ref(null);
  const defaultMonth = ref(null);
  const defaultYear = ref(null);
  const errorMessage = ref(null);
  const infoMessage = ref(null);
  const person = ref(null);

  function init() {
    const now = new Date();
    defaultMonth.value = now.getMonth() + 1;
    defaultYear.value = now.getFullYear();
  }
  init();

  async function loadRequestHistoryForMonth(o) {
    console.log(o);
    infoMessage.value = "Loading ...";
    person.value = null;
    try {
      activities.value = null;
      const url = new URL(
        process.env.VUE_APP_API_PREFIX +
          "requests/" +
          encodeURIComponent(o.year) +
          "/" +
          encodeURIComponent(o.monthIndex)
      );
      let resp = null;
      console.log("API URL: " + url);
      await refreshTokenIfRequired(router);
      let auth_info = loadAuthInfo();
      let xsrf_token = auth_info["xsrf_token"];
      try {
        resp = await fetch(url, {
          credentials: "include",
          method: "GET",
          headers: {
            "X-XSRF-TOKEN": xsrf_token,
            "Content-Type": "application/json",
          },
        });
      } catch (ex) {
        console.log("Error during API call: " + ex);
      } finally {
        console.log("Hide 'Loading ...' indicator.");
      }
      if (!resp.ok) {
        console.log("HTTP error during API call: " + resp.status);
        let error_message = "An error occured during form processing.";
        try {
          let result = await resp.json();
          error_message = result["error"];
        } catch (ex) {
          console.log("Could not decode JSON error message.");
        }
        console.log(error_message);
        return;
      }
      let result = await resp.json();
      console.log(result);
      activities.value = result;
    } finally {
      infoMessage.value = null;
    }
  }

  function getActionDate(item) {
    const details = item.action;
    const action = details.action;
    let result = null;
    switch (action) {
      case "hire":
        result = dateToMMDDYYYY(tstampToDate(details.employment_date));
        break;
      case "term":
      case "retire":
        result = dateToMMDDYYYY(tstampToDate(details.termination_date));
        break;
      case "transfer":
        result = dateToMMDDYYYY(tstampToDate(details.transfer_date));
        break;
      case "cancellation":
        result = dateToMMDDYYYY(tstampToDate(item.issued));
        break;
    }
    return result;
  }

  async function handlePersonDetails(item) {
    const person_id = item.person_id;
    infoMessage.value = "Loading ...";
    person.value = null;
    let result = null;
    try {
      await refreshTokenIfRequired(router);
      result = await lookupPersonRecord(person_id);
      person.value = result;
    } finally {
      infoMessage.value = null;
    }
  }

  async function cancelPendingTermination(e, item, index) {
    console.log(item);
    errorMessage.value = null;
    infoMessage.value = "Processing ...";
    try {
      await cancelPendingTerminationApi(item);
      activities.value[index].status = "cancelled";
    } finally {
      infoMessage.value = null;
    }
  }

  async function cancelPendingTerminationApi(item) {
    let person_id = item.person_id;
    const url = new URL(
      process.env.VUE_APP_API_PREFIX +
        "activity/" +
        encodeURIComponent(person_id)
    );
    url.searchParams.set("reqid", item.reqid);
    url.searchParams.set("yyyymm", item.yyyymm);
    let resp = null;
    await refreshTokenIfRequired(router);
    let auth_info = loadAuthInfo();
    let xsrf_token = auth_info["xsrf_token"];
    console.log("Calling API: " + url);
    try {
      resp = await fetch(url, {
        credentials: "include",
        method: "DELETE",
        headers: {
          "X-XSRF-TOKEN": xsrf_token,
          "Content-Type": "application/json",
        },
      });
    } catch (ex) {
      console.log("Error during API call: " + ex);
      errorMessage.value = "Error cancelling pending termination.";
      return;
    }
    if (!resp.ok) {
      console.log("HTTP error during API call: " + resp.status);
      let error_message = "An error occured during form processing.";
      try {
        let result = await resp.json();
        error_message = result["error"];
      } catch (ex) {
        console.log("Could not decode JSON error message.");
      }
      console.log(error_message);
      errorMessage.value = "Error retrieving activity log.";
      return;
    }
    let data = await resp.json();
    console.log(data);
  }
  return {
    activities,
    cancelPendingTermination,
    defaultMonth,
    defaultYear,
    errorMessage,
    infoMessage,
    getActionDate,
    handlePersonDetails,
    loadRequestHistoryForMonth,
    person,
  };
}
export { requestHistorySetup };
