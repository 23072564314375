<template>
  <div class="home">
    <div class="wide-panel">
      <month-picker-input
        class="center"
        :clearable="false"
        :input-pre-filled="true"
        :default-month="defaultMonth"
        :default-year="defaultYear"
        variant="dark"
        @change="loadRequestHistoryForMonth"
      ></month-picker-input>

      <transition name="shrink-fade">
        <div class="wide-panel error" v-if="errorMessage">
          <p>
            {{ errorMessage }}
          </p>
        </div>
      </transition>

      <table class="data_table">
        <thead>
          <th>Person ID</th>
          <th>Action</th>
          <th title="The date the action took place.">Date</th>
          <th>Job Title</th>
          <th>Faculty</th>
          <th title="The date the request was made.">Issued</th>
          <th>Requestor</th>
          <th>Status</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in activities" v-bind:key="item.reqid">
            <td>
              <a href="#" @click.prevent="handlePersonDetails(item)">{{
                item.person_id
              }}</a>
            </td>
            <td>{{ item.action.action }}</td>
            <td>{{ getActionDate(item) }}</td>
            <td>{{ item.action.job_title }}</td>
            <td>
              <font-awesome-icon
                icon="check-square"
                v-if="item.action.is_faculty"
              />
            </td>
            <td>{{ dateToMMDDYYYY(tstampToDate(item.issued)) }}</td>
            <td>{{ item.user_id }}</td>
            <td>{{ item.status }}</td>
            <td>
              <font-awesome-icon
                icon="trash"
                v-if="item.status == 'pending' && item.action.action == 'term'"
                title="Cancel a pending termination request."
                @click="cancelPendingTermination($event, item, index)"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <transition name="shrink-fade">
        <div class="wide-panel" v-if="infoMessage">
          <p>
            {{ infoMessage }}
          </p>
        </div>
      </transition>

      <transition name="shrink-fade">
        <div class="panel" v-if="person">
          <h4>Person Details</h4>
          <table class="data_table" id="person">
            <tbody>
              <tr v-for="(value, name) in person" :key="name">
                <template v-if="!name.endsWith('_')">
                  <td class="label">
                    {{ name.replaceAll("_", " ").toLowerCase() }}
                  </td>
                  <td>{{ value }}</td>
                </template>
              </tr>
            </tbody>
          </table>

          <div class="center top-spacer">
            <a class="button action" @click.prevent="person = null">Close</a>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { onMounted } from "vue";
import { refreshTokenIfRequired, testToken } from "@/utils/token";
import { requestHistorySetup } from "@/compositions/requestHistory";
import { useRouter } from "vue-router";
import { tstampToDate, dateToMMDDYYYY } from "@/utils/datetime";

export default {
  name: "RequestHistory",
  setup() {
    const router = useRouter();

    // Refresh the access token if required when mounting this component.
    async function initView() {
      await refreshTokenIfRequired();
      testToken();
    }
    onMounted(initView);

    const symbolMap = requestHistorySetup(router);
    return { ...symbolMap, tstampToDate, dateToMMDDYYYY };
  },
};
</script>
<style></style>
