<template>
  <div class="home">
    <h3>Logout</h3>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { onMounted } from "vue";
import { removeAuthInfo, testToken } from "@/utils/token";

export default {
  name: "Logout",
  setup() {
    console.log("Logout - setup");

    onMounted(async () => {
      let resp = await fetch(process.env.VUE_APP_API_PREFIX + "logout", {
        credentials: "include",
      });
      await resp.json();
      removeAuthInfo();
      testToken();
      window.location.href = process.env.VUE_APP_CAS_LOGOUT;
    });
    return {};
  },
};
</script>
