<template>
  <div class="home">
    <h1>Accounts Workflow</h1>
    <img src="@/assets/images/workflow.svg" />
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { onMounted } from "vue";
import { refreshTokenIfRequired, testToken } from "@/utils/token";

export default {
  name: "Home",
  setup() {
    // Refresh the access token if required when mounting this component.
    async function refreshAndTest() {
      await refreshTokenIfRequired();
      testToken();
    }
    onMounted(refreshAndTest);

    return {};
  },
};
</script>
