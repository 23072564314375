<template>
  <div id="nav">
    <router-link
      v-for="(route, index) in router.options.routes"
      :key="route.path"
      :to="route.path"
    >
      <span v-if="showNavLink(route)">
        <span v-if="index != 0"> | </span>{{ route.name }}
      </span>
    </router-link>
  </div>
</template>

<script>
// @ is an alias to /src
import { hasUnexpiredToken } from "@/utils/token";
import { useRouter } from "vue-router";
import { isAuthorized } from "@/utils/permissions";

export default {
  name: "Navbar",
  setup() {
    console.log("setup Nabar");
    const router = useRouter();
    console.log(router.options.routes);

    function needsAuth(route) {
      if ("meta" in route) {
        if ("requiresAuth" in route.meta) {
          if (route.meta.requiresAuth) {
            if (!hasUnexpiredToken.value) {
              return true;
            }
          }
        }
        if ("requiresPerm" in route.meta) {
          if (route.meta.requiresPerm) {
            if (!isAuthorized(route.meta.requiresPerm)) {
              return true;
            }
          }
        }
      }
      return false;
    }

    function showNavLink(route) {
      console.log("Show link for route " + route.name + "?");
      if (needsAuth(route)) {
        return false;
      }
      console.log("Doesn't need auth.");
      if (route.name == "Login" && hasUnexpiredToken.value) {
        return false;
      }
      return true;
    }

    return { router, showNavLink };
  },
};
</script>
