<template>
  <div class="home">
    <h1>Accounts Workflow</h1>
    <h3>Login</h3>
    <div v-if="showLoginWidget">
      <p>Oops! Something went wrong while trying to log you in. Try again?</p>
      <button @click="loginWithCas">Log in</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { saveAuthInfo } from "@/utils/token";

export default {
  name: "Login",
  setup() {
    console.log("Login - setup()");

    const router = useRouter();
    const route = useRoute();
    const showLoginWidget = ref(false);

    function loginWithCas() {
      window.location.href = process.env.VUE_APP_CAS_LOGIN;
    }

    onMounted(async () => {
      const ticket = getCasServiceTicket(route);
      if (ticket) {
        console.log("CAS service ticket present.");
        let authenticated = await validateCasTicket(ticket);
        if (authenticated) {
          router.push("/");
        } else {
          // Handle failed logins here.
          showLoginWidget.value = true;
        }
      } else {
        console.log(
          "No service ticket.  Redirecting to `" +
            process.env.VUE_APP_CAS_LOGIN +
            "` ..."
        );
        loginWithCas();
      }
    });
    return { showLoginWidget, loginWithCas };
  },
};

function getCasServiceTicket(route) {
  console.log("Login component mounted.");
  const ticket = route.query.ticket;
  return ticket;
}

async function validateCasTicket(ticket) {
  let payload = { ticket: ticket };
  let resp = null;
  try {
    resp = await fetch(process.env.VUE_APP_API_PREFIX + "authn-cas", {
      credentials: "include",
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
  } catch (ex) {
    console.log("Error attempting to authenticate to CAS.");
    return false;
  }
  if (!resp.ok) {
    console.log(
      "Response code " +
        resp.status +
        " when attempting to authenticate to CAS."
    );
    return false;
  }
  let auth_info = await resp.json();
  console.log(auth_info);
  saveAuthInfo(auth_info);
  return true;
}
</script>
