import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Logout from "@/views/Logout.vue";
import EmployeeNetid from "@/views/EmployeeNetid.vue";
import RequestHistory from "@/views/RequestHistory.vue";
import Subscribe from "@/views/Subscribe.vue";
import { ensureTokenNotExpired } from "@/utils/token";
import { testToken } from "@/utils/token";
import { permAdmin, permUser, permSubscriber } from "@/utils/permissions";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/employee",
    name: "Employee NetIDs",
    component: EmployeeNetid,
    beforeEnter: ensureTokenNotExpired,
    meta: { requiresPerm: permAdmin | permUser },
  },
  {
    path: "/requests",
    name: "Request History",
    component: RequestHistory,
    beforeEnter: ensureTokenNotExpired,
    meta: { requiresPerm: permAdmin | permUser },
  },
  {
    path: "/subscribe",
    name: "Subscribe",
    component: Subscribe,
    beforeEnter: ensureTokenNotExpired,
    meta: { requiresPerm: permAdmin | permUser | permSubscriber },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  document.title = to.name;
  testToken();
});

export default router;
