import { ref } from "vue";

const hasUnexpiredToken = ref(false);

function saveAuthInfo(auth_info) {
  window.sessionStorage.setItem("auth_info", JSON.stringify(auth_info));
}

function loadAuthInfo() {
  let auth_info = window.sessionStorage.getItem("auth_info");
  if (!auth_info) return null;
  return JSON.parse(auth_info);
}

function removeAuthInfo() {
  window.sessionStorage.removeItem("auth_info");
}

function ensureTokenNotExpired(to, from, next) {
  if (hasUnexpiredToken.value) {
    next();
  } else {
    next("/login");
  }
}

function testIfJwtExpired(auth_info) {
  let expiration = auth_info["expiration"];
  let now = Date.now() / 1000;
  console.log("expiration: " + expiration + ", now: " + now);
  if (expiration < now) {
    return true;
  }
  return false;
}

function testToken() {
  let auth_info = loadAuthInfo();
  if (auth_info) {
    console.log("Auth info exists.");
    if (testIfJwtExpired(auth_info)) {
      console.log("Token has expired.");
      hasUnexpiredToken.value = false;
      return;
    }
    console.log("Token has not expired.");
  } else {
    console.log("Token does not exist.");
    hasUnexpiredToken.value = false;
    return;
  }
  hasUnexpiredToken.value = true;
  return;
}

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

async function refreshTokenIfRequired(router = null) {
  var refreshRequired = false;
  let auth_info = loadAuthInfo();
  if (!auth_info) {
    console.log("No auth info-- refresh required.");
    refreshRequired = true;
  } else if (testIfJwtExpired(auth_info)) {
    console.log("Access token expired-- refresh required.");
    refreshRequired = true;
  }
  if (refreshRequired) {
    // Refresh required.
    const url = new URL(process.env.VUE_APP_API_PREFIX + "auth-refresh");
    let resp = null;
    console.log("Making API call to refresh access token ...");
    try {
      resp = await fetch(url, {
        credentials: "include",
        method: "GET",
      });
    } catch (ex) {
      console.log("Error trying to connect to refresh token API: " + ex);
      return;
    }
    if (resp.status == 401) {
      console.log("Refresh token has expired.");
      if (router !== null) {
        router.push("/login");
      } else {
        return;
      }
    }
    if (!resp.ok) {
      console.log(
        "Response code " + resp.status + " returned from refresh token API."
      );
    }
    let auth_info = await resp.json();
    console.log("New auth_info: " + auth_info);
    saveAuthInfo(auth_info);
  }
}

export {
  ensureTokenNotExpired,
  hasUnexpiredToken,
  loadAuthInfo,
  parseJwt,
  refreshTokenIfRequired,
  removeAuthInfo,
  saveAuthInfo,
  testToken,
};
