import { ref } from "vue";
import { loadAuthInfo, refreshTokenIfRequired } from "@/utils/token";

function subscribeSetup(router) {
  const infoMessage = ref(null);
  const errorMessage = ref(null);

  function init() {
    console.log(router);
  }
  init();

  async function subscribeToNotifications() {
    errorMessage.value = null;
    infoMessage.value = "Subscribing ...";
    try {
      const url = new URL(process.env.VUE_APP_API_PREFIX + "subscribe");
      let resp = null;
      console.log("API URL: " + url);
      await refreshTokenIfRequired(router);
      let auth_info = loadAuthInfo();
      let xsrf_token = auth_info["xsrf_token"];
      try {
        resp = await fetch(url, {
          credentials: "include",
          method: "POST",
          headers: {
            "X-XSRF-TOKEN": xsrf_token,
            "Content-Type": "application/json",
          },
        });
      } catch (ex) {
        console.log("Error during API call: " + ex);
        errorMessage.value = "Error during API call.";
      } finally {
        infoMessage.value = null;
      }
      if (!resp.ok) {
        console.log("HTTP error during API call: " + resp.status);
        let error_message = "An error occured during form processing.";
        try {
          let result = await resp.json();
          error_message = result["error"];
        } catch (ex) {
          console.log("Could not decode JSON error message.");
        }
        console.log(error_message);
        return;
      }
      let result = await resp.json();
      console.log(result);
    } finally {
      infoMessage.value = "Successfully subscribed!";
    }
  }

  return {
    errorMessage,
    infoMessage,
    subscribeToNotifications,
  };
}
export { subscribeSetup };
