/* Convert UNIX timestamp to a local Date object.
 */
function tstampToDate(ts) {
  return new Date(ts * 1000);
}

function dateToMMDDYYYY(d) {
  const options = {
    weekday: undefined,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  return d.toLocaleDateString("en-US", options);
}

export { tstampToDate, dateToMMDDYYYY };
