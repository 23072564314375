import { ref } from "vue";
import { loadAuthInfo, refreshTokenIfRequired } from "@/utils/token";
import { tstampToDate } from "@/utils/datetime";
import { lookupPersonRecord } from "@/utils/person";

function employeeForms(router) {
  const activityLog = ref(null);
  const formErrorMessage = ref(null);
  const hireForm = ref(null);
  const isFaculty = ref(false);
  const isValidationError = ref(false);
  const jobTitle = ref("");
  const lnumber = ref("");
  const netidStatus = ref(null);
  const person = ref(null);
  const personSearchError = ref(false);
  const replaces = ref(null);
  const retireForm = ref(null);
  const showActivityLog = ref(false);
  const showError = ref(false);
  const showHireForm = ref(false);
  const showPersonActionsForm = ref(false);
  const showPersonSearchForm = ref(true);
  const showProcessingForm = ref(false);
  const showRetireForm = ref(false);
  const showSearchingForPerson = ref(false);
  const showSuccess = ref(false);
  const showTermForm = ref(false);
  const showTransferForm = ref(false);
  const supervisorEmail = ref(null);
  const termForm = ref(null);
  const transferForm = ref(null);
  const employmentDate = ref("");
  const employmentEndDate = ref(null);

  function datestamp() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    return today;
  }

  // Reset all state except what is explicitly preserved.
  function resetState(preserve) {
    let ds = datestamp();
    if (!preserve.formErrorMessage) formErrorMessage.value = null;
    if (!preserve.employmentDate) employmentDate.value = ds;
    if (!preserve.employmentEndDate) employmentEndDate.value = null;
    if (!preserve.isFaculty) isFaculty.value = false;
    if (!preserve.isValidationError) isValidationError.value = false;
    if (!preserve.jobTitle) jobTitle.value = "";
    if (!preserve.lnumber) lnumber.value = "";
    if (!preserve.netidStatus) netidStatus.value = null;
    if (!preserve.person) person.value = null;
    if (!preserve.personSearchError) personSearchError.value = false;
    if (!preserve.replaces) replaces.value = "";
    if (!preserve.showActivityLog) showActivityLog.value = false;
    if (!preserve.showError) showError.value = false;
    if (!preserve.showHireForm) showHireForm.value = false;
    if (!preserve.showPersonActionsForm) showPersonActionsForm.value = false;
    if (!preserve.showPersonSearchForm) showPersonSearchForm.value = false;
    if (!preserve.showProcessingForm) showProcessingForm.value = false;
    if (!preserve.showRetireForm) showRetireForm.value = false;
    if (!preserve.showSearchingForPerson) showSearchingForPerson.value = false;
    if (!preserve.showSuccess) showSuccess.value = false;
    if (!preserve.showTermForm) showTermForm.value = false;
    if (!preserve.showTransferForm) showTransferForm.value = false;
    if (!preserve.supervisorEmail) supervisorEmail.value = null;
  }

  // Handle traversing back to the person actions menu.
  function backToActions() {
    let preserve = new Object();
    preserve.netidStatus = true;
    preserve.person = true;
    preserve.showPersonActionsForm = true;
    resetState(preserve);
    showPersonActionsForm.value = true;
  }

  // Handler for showing the activity log.
  async function initActivityLog() {
    let preserve = new Object();
    preserve.netidStatus = true;
    preserve.person = true;
    resetState(preserve);
    showSearchingForPerson.value = true;
    await loadActivityLogForPerson();
    showSearchingForPerson.value = false;
    showActivityLog.value = true;
  }

  // Handler for initializing the new hire form.
  function initHireForm() {
    let preserve = new Object();
    preserve.netidStatus = true;
    preserve.person = true;
    preserve.showHireForm = true;
    preserve.formErrorMessage = true;
    resetState(preserve);
    if (person.value["faculty_"]) {
      isFaculty.value = true;
    }
    showHireForm.value = true;
  }

  // Handler for the hire form actually being submitted.
  async function processHireForm() {
    let data = {
      action: "hire",
      employmentDate: employmentDate.value,
      employmentEndDate: employmentEndDate.value,
      jobTitle: jobTitle.value,
      isFaculty: isFaculty.value,
      supervisorEmail: supervisorEmail.value,
      replaces: replaces.value,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    await processEmploymentActionForm(hireForm, showHireForm, data);
  }

  // Handler for initializing the term form.
  function initTermForm() {
    let preserve = new Object();
    preserve.netidStatus = true;
    preserve.person = true;
    preserve.showTermForm = true;
    resetState(preserve);
    employmentEndDate.value = datestamp();
    showTermForm.value = true;
  }

  // Handler for to process the term form.
  async function processTermForm() {
    let data = {
      action: "term",
      terminationDate: employmentEndDate.value,
      jobTitle: jobTitle.value,
      supervisorEmail: supervisorEmail.value,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    await processEmploymentActionForm(termForm, showTermForm, data);
  }

  // Handler for initializing the retire form.
  function initRetireForm() {
    let preserve = new Object();
    preserve.netidStatus = true;
    preserve.person = true;
    preserve.showTermForm = true;
    resetState(preserve);
    employmentEndDate.value = datestamp();
    showRetireForm.value = true;
  }

  // Handler for to process the term form.
  async function processRetireForm() {
    let data = {
      action: "retire",
      terminationDate: employmentEndDate.value,
      jobTitle: jobTitle.value,
      supervisorEmail: supervisorEmail.value,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    await processEmploymentActionForm(retireForm, showRetireForm, data);
  }

  // Handler for initializing the transfer form.
  function initTransferForm() {
    let preserve = new Object();
    preserve.netidStatus = true;
    preserve.person = true;
    preserve.showTransferForm = true;
    resetState(preserve);
    showTransferForm.value = true;
  }

  // Handler for the hire form actually being submitted.
  async function processTransferForm() {
    let data = {
      action: "transfer",
      transferDate: employmentDate.value,
      jobTitle: jobTitle.value,
      isFaculty: isFaculty.value,
      supervisorEmail: supervisorEmail.value,
      replaces: replaces.value,
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    await processEmploymentActionForm(transferForm, showTransferForm, data);
  }

  // General employment action form handler.
  async function processEmploymentActionForm(form, showForm, data) {
    if (!form.value.checkValidity()) {
      isValidationError.value = true;
      var inputs = [...form.value.getElementsByTagName("input")];
      inputs.some((field) => {
        if (!field.validity.valid) {
          field.focus();
          formErrorMessage.value =
            "One or more form fields have errors and must be corrected.";
          return true;
        }
        return false;
      });
      return;
    }
    // Deactivate any previous errors.
    formErrorMessage.value = null;
    // "Submit" the form to the API.
    console.log("Form data: ", data);
    showForm.value = false;
    showProcessingForm.value = true;
    const url = new URL(
      process.env.VUE_APP_API_PREFIX +
        "netid/" +
        encodeURIComponent(person.value["LNUMBER"])
    );
    let resp = null;
    console.log("API URL: " + url);
    await refreshTokenIfRequired(router);
    let auth_info = loadAuthInfo();
    let xsrf_token = auth_info["xsrf_token"];
    try {
      resp = await fetch(url, {
        credentials: "include",
        method: "POST",
        headers: {
          "X-XSRF-TOKEN": xsrf_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (ex) {
      console.log("Error during API call: " + ex);
      showError.value = true;
    } finally {
      showProcessingForm.value = false;
    }
    if (!resp.ok) {
      console.log("HTTP error during API call: " + resp.status);
      showForm.value = true;
      let error_message = "An error occured during form processing.";
      try {
        let result = await resp.json();
        error_message = result["error"];
      } catch (ex) {
        console.log("Could not decode JSON error message.");
      }
      console.log(error_message);
      formErrorMessage.value = error_message;
      return;
    }
    let result = await resp.json();
    console.log(result);
    console.log("success");
    let preserve = new Object();
    preserve.showSuccess = true;
    resetState(preserve);
    showSuccess.value = true;
  }

  // Handler to start a new search.
  function newSearch() {
    let preserve = new Object();
    preserve.showPersonSearchForm = true;
    resetState(preserve);
    showPersonSearchForm.value = true;
  }

  // Callback to retrieve person info.
  async function getPersonInfo() {
    // Initialize states.
    let preserve = new Object();
    preserve.lnumber = true;
    preserve.showSearchingForPerson = true;
    resetState(preserve);
    showSearchingForPerson.value = true;
    const person_id = lnumber.value;
    // Look up person record and check if NetID exists and has employment
    // entitlement.
    await refreshTokenIfRequired(router);
    let results = null;
    try {
      results = await Promise.all([
        lookupPersonRecord(person_id),
        getNetidStatus(person_id),
      ]);
    } catch (ex) {
      showSearchingForPerson.value = false;
      personSearchError.value = true;
      formErrorMessage.value = "Error searching for person.";
      console.log(ex);
      return;
    }
    showSearchingForPerson.value = false;
    person.value = results[0];
    console.log(person.value);
    netidStatus.value = results[1];
    showPersonActionsForm.value = true;
  }

  // Look up a NetID and employee cohort status from an LDAP directory
  // and return it.
  async function getNetidStatus(person_id) {
    const url = new URL(
      process.env.VUE_APP_API_PREFIX + "netid/" + encodeURIComponent(person_id)
    );
    let resp = null;
    let auth_info = loadAuthInfo();
    let xsrf_token = auth_info["xsrf_token"];
    console.log("Calling API: " + url);
    resp = await fetch(url, {
      credentials: "include",
      method: "GET",
      headers: {
        "X-XSRF-TOKEN": xsrf_token,
        "Content-Type": "application/json",
      },
    });
    if (!resp.ok) {
      const msg = "NetID API returned status " + resp.status;
      console.log(msg);
      const err = new Error(msg);
      throw err;
    }
    let data = await resp.json();
    console.log(data);
    return data;
  }

  // Load the activity log for a person.
  async function loadActivityLogForPerson() {
    let person_id = person.value["LNUMBER"];
    const url = new URL(
      process.env.VUE_APP_API_PREFIX +
        "activity/" +
        encodeURIComponent(person_id)
    );
    let resp = null;
    await refreshTokenIfRequired(router);
    let auth_info = loadAuthInfo();
    let xsrf_token = auth_info["xsrf_token"];
    console.log("Calling API: " + url);
    try {
      resp = await fetch(url, {
        credentials: "include",
        method: "GET",
        headers: {
          "X-XSRF-TOKEN": xsrf_token,
          "Content-Type": "application/json",
        },
      });
    } catch (ex) {
      console.log("Error during API call: " + ex);
      formErrorMessage.value = "Error retrieving activity log.";
      activityLog.value = [];
      return;
    }
    if (!resp.ok) {
      console.log("HTTP error during API call: " + resp.status);
      let error_message = "An error occured during form processing.";
      try {
        let result = await resp.json();
        error_message = result["error"];
      } catch (ex) {
        console.log("Could not decode JSON error message.");
      }
      console.log(error_message);
      formErrorMessage.value = "Error retrieving activity log.";
      activityLog.value = [];
      return;
    }
    let data = await resp.json();
    console.log(data);
    activityLog.value = data;
  }

  function formatActivityDetails(item) {
    let tooltip = "";
    let action = item.action;
    switch (action.action) {
      case "hire":
        tooltip =
          "Employment Date: " +
          tstampToDate(action.employment_date).toDateString();
        if (action.employment_end_date) {
          tooltip +=
            "\nEmployment End Date: " +
            tstampToDate(action.employment_end_date).toDateString();
        }
        break;
      case "transfer":
        tooltip =
          "Transfer Date: " + tstampToDate(action.transfer_date).toDateString();
        break;
      case "term":
      case "retire":
        tooltip =
          "Termination Date: " +
          tstampToDate(action.termination_date).toDateString();
        break;
      case "cancellation":
        tooltip =
          "Original Termination Date: " +
          tstampToDate(action.termination_date).toDateString();
        break;
    }
    return tooltip;
  }

  async function cancelPendingTermination(e, item) {
    console.log(item);
    formErrorMessage.value = null;
    showActivityLog.value = false;
    showProcessingForm.value = true;
    try {
      await cancelPendingTerminationApi(item);
      showProcessingForm.value = false;
      backToActions();
    } finally {
      showProcessingForm.value = false;
    }
  }

  async function cancelPendingTerminationApi(item) {
    let person_id = person.value["LNUMBER"];
    const url = new URL(
      process.env.VUE_APP_API_PREFIX +
        "activity/" +
        encodeURIComponent(person_id)
    );
    url.searchParams.set("reqid", item.reqid);
    url.searchParams.set("yyyymm", item.yyyymm);
    let resp = null;
    await refreshTokenIfRequired(router);
    let auth_info = loadAuthInfo();
    let xsrf_token = auth_info["xsrf_token"];
    console.log("Calling API: " + url);
    try {
      resp = await fetch(url, {
        credentials: "include",
        method: "DELETE",
        headers: {
          "X-XSRF-TOKEN": xsrf_token,
          "Content-Type": "application/json",
        },
      });
    } catch (ex) {
      console.log("Error during API call: " + ex);
      formErrorMessage.value = "Error cancelling pending termination.";
      return;
    }
    if (!resp.ok) {
      console.log("HTTP error during API call: " + resp.status);
      let error_message = "An error occured during form processing.";
      try {
        let result = await resp.json();
        error_message = result["error"];
      } catch (ex) {
        console.log("Could not decode JSON error message.");
      }
      console.log(error_message);
      formErrorMessage.value = "Error retrieving activity log.";
      return;
    }
    let data = await resp.json();
    console.log(data);
  }

  return {
    activityLog,
    backToActions,
    cancelPendingTermination,
    employmentDate,
    employmentEndDate,
    formatActivityDetails,
    formErrorMessage,
    getPersonInfo,
    hireForm,
    initActivityLog,
    initHireForm,
    initRetireForm,
    initTermForm,
    initTransferForm,
    isFaculty,
    isValidationError,
    jobTitle,
    lnumber,
    netidStatus,
    newSearch,
    person,
    personSearchError,
    processHireForm,
    processRetireForm,
    processTermForm,
    processTransferForm,
    replaces,
    retireForm,
    showActivityLog,
    showError,
    showHireForm,
    showPersonActionsForm,
    showPersonSearchForm,
    showProcessingForm,
    showRetireForm,
    showSearchingForPerson,
    showSuccess,
    showTermForm,
    showTransferForm,
    supervisorEmail,
    termForm,
    transferForm,
  };
}

export { employeeForms };
