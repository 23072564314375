import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { config } from "@fortawesome/fontawesome-svg-core";
import { faCheckSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { MonthPicker } from "vue-month-picker";
import { MonthPickerInput } from "vue-month-picker";
import "@fortawesome/fontawesome-svg-core/styles.css";

library.add(faCheckSquare);
library.add(faTrash);

// Make sure you tell Font Awesome to skip auto-inserting CSS into the <head>
config.autoAddCss = false;
// config.autoAddCss = true;

let app = createApp(App).use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.component("month-picker", MonthPicker);
app.component("month-picker-input", MonthPickerInput);
app.mount("#app");
