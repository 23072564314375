<template>
  <div>
    <h3>Success</h3>
    <div>
      <p>Your request has been submitted and will be processed shortly.</p>
      <a class="button menu" @click="emitOK">OK</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessPane",
  setup(props, { emit }) {
    function emitOK() {
      emit("successComplete");
    }

    return { emitOK };
  },
};
</script>
<style>
a.menu {
  background-color: #9a4ef1;
}

a.button {
  display: inline-block;
  padding: 0.3em 1.2em;
  margin: 0 0.1em 0.1em 0;
  border: 0.16em solid rgba(255, 255, 255, 0);
  border-radius: 2em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #ffffff;
  text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
  text-align: center;
  transition: all 0.2s;
}
a.button:hover {
  border-color: rgba(0, 0, 0, 1);
}
@media all and (max-width: 30em) {
  a.button {
    display: block;
    margin: 0.2em auto;
  }
}
</style>
