import { loadAuthInfo } from "@/utils/token";

const permAdmin = 2 ** 0;
const permUser = 2 ** 1;
const permSubscriber = 2 ** 2;

function isAuthorized(requiredPermissions) {
  const auth_info = loadAuthInfo();
  if (!auth_info) {
    console.log("No authorization info!");
    return false;
  }
  const permissions = auth_info["permissions"];
  return requiredPermissions & permissions;
}

export { isAuthorized, permAdmin, permUser, permSubscriber };
