import { loadAuthInfo } from "@/utils/token";

// Look up a person record from the ERP and return it.
async function lookupPersonRecord(person_id) {
  const url = new URL(process.env.VUE_APP_API_PREFIX + "person");
  url.searchParams.set("lnumber", person_id);
  let auth_info = loadAuthInfo();
  let xsrf_token = auth_info["xsrf_token"];
  console.log("Calling API: " + url);
  let resp = await fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      "X-XSRF-TOKEN": xsrf_token,
      "Content-Type": "application/json",
    },
  });
  if (!resp.ok) {
    const msg = "Person API returned status " + resp.status;
    console.log(msg);
    const err = new Error(msg);
    throw err;
  }
  let data = await resp.json();
  console.log(data["person"]);
  return data["person"];
}

export { lookupPersonRecord };
