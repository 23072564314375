<template>
  <div class="home">
    <h3>Subscribe to Notifications</h3>

    <transition name="shrink-fade">
      <div class="panel error" v-if="errorMessage">
        <p>
          {{ errorMessage }}
        </p>
      </div>
    </transition>

    <transition name="shrink-fade">
      <div class="panel" v-if="infoMessage">
        <p>
          {{ infoMessage }}
        </p>
      </div>
    </transition>

    <div class="panel center">
      <a class="button action" @click.prevent="subscribeToNotifications"
        >Subscribe</a
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import { onMounted } from "vue";
import { refreshTokenIfRequired, testToken } from "@/utils/token";
import { subscribeSetup } from "@/compositions/subscriptions";

export default {
  name: "Subscribe",
  setup() {
    // Refresh the access token if required when mounting this component.
    async function refreshAndTest() {
      await refreshTokenIfRequired();
      testToken();
    }
    onMounted(refreshAndTest);

    const symbolMap = subscribeSetup();
    return { ...symbolMap };
  },
};
</script>
