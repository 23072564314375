<template>
  <div>
    <h3>An Error Occurred ...</h3>
    <div>
      <p>
        Oh dear! It looks like something went wrong! Try refreshing the page. If
        that doesn't work, please contact the ITS help desk.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPane",
};
</script>
